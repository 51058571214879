import { FormikValues } from "formik";
import JourneyHelper from "../utils/journeyHelper";
import { MALE, T_SHIRT } from "./fields";
import {
  DRYATHLON,
  JourneyTypes,
  SF_CHALLENGES,
} from "./journeys/journeyNames";

type Header = {
  title: string;
  abbr?: string;
  detail?: string;
};

type CommonSizeData = {
  sizeLabel: string;
};

export type CmInchesData = CommonSizeData & {
  cm: string;
  inches: string;
};

export type SizesData = CommonSizeData & {
  XXS: string;
  XS: string;
  S: string;
  M: string;
  L: string;
  XL: string;
  XXL: string;
};

type SizeData = CmInchesData | SizesData;

export type SizeGuideValuesType = {
  headers: Header[];
  data: SizeData[];
};

export class SizeGuideValues {
  readonly isMale: boolean;
  readonly isTeeShirt: boolean;
  readonly themeSizes: {
    [key: string]: SizeGuideValuesType;
  };

  constructor(values: FormikValues) {
    this.isMale = values.maleFemaleStyles === MALE;
    this.isTeeShirt = values.tshirtOrVest === T_SHIRT;
    this.themeSizes = {
      cruk: this.crukTeeShirtSizes(),
      su2c: this.su2CTeeShirtSizes(),
      rfl: this.crukTeeShirtSizes(),
    };
  }

  currentSize(theme: "cruk" | "su2c" | "rfl", journey: JourneyTypes) {
    const { isSportsFormJourney } = new JourneyHelper();

    if (isSportsFormJourney(journey)) {
      return this.sportsTopsSizes();
    }

    switch (journey) {
      case SF_CHALLENGES:
        return this.sfChallengeTeeShirtSizes();
      case DRYATHLON:
        return this.dryathlonTeeShirtSizes();
      default:
        return this.themeSizes[theme];
    }
  }

  sfChallengeTechTopSizes(): SizeGuideValuesType {
    return {
      headers: [
        { title: "Size" },
        { title: "Chest", abbr: "cm", detail: "front" },
        { title: "Chest", abbr: "in", detail: "front" },
      ],
      data: [
        {
          sizeLabel: "S",
          cm: "52.5",
          inches: "21",
        },
        {
          sizeLabel: "M",
          cm: "56",
          inches: "22",
        },
        {
          sizeLabel: "L",
          cm: "58.5",
          inches: "23",
        },
        {
          sizeLabel: "XL",
          cm: "63",
          inches: "25",
        },
        {
          sizeLabel: "2XL",
          cm: "65",
          inches: "26",
        },
      ],
    };
  }

  private sfChallengeTeeShirtSizes(): SizeGuideValuesType {
    return {
      headers: [
        { title: "Size" },
        { title: "Chest", abbr: "cm", detail: "front" },
        { title: "Chest", abbr: "in", detail: "front" },
      ],
      data: [
        {
          sizeLabel: "S",
          cm: "50",
          inches: "20",
        },
        {
          sizeLabel: "M",
          cm: "53",
          inches: "21",
        },
        {
          sizeLabel: "L",
          cm: "56",
          inches: "22",
        },
        {
          sizeLabel: "XL",
          cm: "59",
          inches: "23",
        },
        {
          sizeLabel: "2XL",
          cm: "62",
          inches: "24",
        },
      ],
    };
  }

  private crukTeeShirtSizes(): SizeGuideValuesType {
    return {
      headers: [
        { title: "Size" },
        { title: "Chest", abbr: "cm" },
        { title: "Chest", abbr: "in" },
      ],
      data: [
        {
          sizeLabel: "S",
          cm: "89-94",
          inches: "35-37",
        },
        {
          sizeLabel: "M",
          cm: "96.5-101.5",
          inches: "38-40",
        },
        {
          sizeLabel: "L",
          cm: "104-109",
          inches: "41-43",
        },
        {
          sizeLabel: "XL",
          cm: "112-117",
          inches: "44-46",
        },
        {
          sizeLabel: "2XL",
          cm: "119.5-124.5",
          inches: "47-49",
        },
      ],
    };
  }

  private sportsTopsSizes(): SizeGuideValuesType {
    return {
      headers: [
        { title: "Measurement Point in CM" },
        { title: "XXS" },
        { title: "XS" },
        { title: "S" },
        { title: "M" },
        { title: "L" },
        { title: "XL" },
        { title: "XXL" },
      ],
      data: [
        this.isTeeShirt
          ? {
              sizeLabel: "Body Length",
              XXS: this.isMale ? "67.5" : "60",
              XS: this.isMale ? "70.5" : "62.5",
              S: this.isMale ? "73.5" : "65",
              M: this.isMale ? "76.5" : "67.5",
              L: this.isMale ? "79.5" : "70",
              XL: this.isMale ? "82" : "72",
              XXL: this.isMale ? "85" : "74",
            }
          : {
              sizeLabel: "Body Length",
              XXS: this.isMale ? "69" : "63.5",
              XS: this.isMale ? "72" : "65.5",
              S: this.isMale ? "75" : "67.5",
              M: this.isMale ? "78" : "69.5",
              L: this.isMale ? "81" : "72",
              XL: this.isMale ? "84.5" : "74.5",
              XXL: this.isMale ? "87" : "77",
            },
        this.isTeeShirt
          ? {
              sizeLabel: "Chest",
              XXS: this.isMale ? "45.5" : "42",
              XS: this.isMale ? "49" : "44.5",
              S: this.isMale ? "52.5" : "47",
              M: this.isMale ? "56" : "50.5",
              L: this.isMale ? "58.5" : "54",
              XL: this.isMale ? "63" : "57",
              XXL: this.isMale ? "65" : "60",
            }
          : {
              sizeLabel: "Chest",
              XXS: this.isMale ? "47.5" : "41",
              XS: this.isMale ? "51" : "44.5",
              S: this.isMale ? "54.5" : "48",
              M: this.isMale ? "58" : "51.5",
              L: this.isMale ? "61" : "55",
              XL: this.isMale ? "64.5" : "58.5",
              XXL: this.isMale ? "67" : "62",
            },
        this.isTeeShirt
          ? {
              sizeLabel: "Bottom",
              XXS: this.isMale ? "45.5" : "44.5",
              XS: this.isMale ? "49" : "48.5",
              S: this.isMale ? "52.5" : "52.5",
              M: this.isMale ? "56" : "56.5",
              L: this.isMale ? "58.5" : "60.5",
              XL: this.isMale ? "63" : "64",
              XXL: this.isMale ? "65" : "67.5",
            }
          : {
              sizeLabel: "Bottom",
              XXS: this.isMale ? "47" : "46",
              XS: this.isMale ? "50.5" : "50",
              S: this.isMale ? "54" : "54",
              M: this.isMale ? "57.5" : "58",
              L: this.isMale ? "60.5" : "62",
              XL: this.isMale ? "64" : "65.5",
              XXL: this.isMale ? "66.5" : "69",
            },
        this.isTeeShirt
          ? {
              sizeLabel: "Across Shoulder",
              XXS: this.isMale ? "39" : "36",
              XS: this.isMale ? "42" : "38",
              S: this.isMale ? "45" : "40",
              M: this.isMale ? "48" : "42",
              L: this.isMale ? "49.5" : "44",
              XL: this.isMale ? "51" : "46.5",
              XXL: this.isMale ? "53" : "49",
            }
          : {
              sizeLabel: "Across Shoulder",
              XXS: this.isMale ? "31.7" : "31.5",
              XS: this.isMale ? "32.9" : "32.5",
              S: this.isMale ? "34.1" : "33.5",
              M: this.isMale ? "35.3" : "34.5",
              L: this.isMale ? "36.5" : "35.5",
              XL: this.isMale ? "37.5" : "36.7",
              XXL: this.isMale ? "38.5" : "37.9",
            },
      ],
    };
  }

  private su2CTeeShirtSizes(): SizeGuideValuesType {
    return {
      headers: [
        { title: "Size" },
        { title: "Chest", abbr: "cm" },
        { title: "Chest", abbr: "in" },
      ],
      data: [
        {
          sizeLabel: "S",
          cm: "86.5-91.5",
          inches: "34-36",
        },
        {
          sizeLabel: "M",
          cm: "96.5-101.5",
          inches: "38-40",
        },
        {
          sizeLabel: "L",
          cm: "106.5-112",
          inches: "42-44",
        },
        {
          sizeLabel: "XL",
          cm: "117-122",
          inches: "46-48",
        },
        {
          sizeLabel: "2XL",
          cm: "127-132",
          inches: "50-52",
        },
      ],
    };
  }

  private dryathlonTeeShirtSizes(): SizeGuideValuesType {
    return {
      headers: [
        { title: "Size" },
        { title: "Chest", abbr: "cm" },
        { title: "Chest", abbr: "in" },
      ],

      data: [
        {
          sizeLabel: "S",
          cm: "86-91",
          inches: "34-36",
        },
        {
          sizeLabel: "M",
          cm: "97-102",
          inches: "38-40",
        },
        {
          sizeLabel: "L",
          cm: "107-112",
          inches: "42-44",
        },
        {
          sizeLabel: "XL",
          cm: "117-122",
          inches: "46-48",
        },
        {
          sizeLabel: "2XL",
          cm: "127-132",
          inches: "50-52",
        },
      ],
    };
  }
}
