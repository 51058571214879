import {
  Box,
  Collapse,
  ErrorText,
  LegendWrapper,
  Radio,
} from "@cruk/cruk-react-components";
import SizeGuide from "../../components/SizeGuide";
import { FormFieldProps } from "../../types";

const RadioSelectTShirtVestSize = ({
  values,
  field,
  journeyHelper,
  journey,
  getFieldProps,
  touched,
  errors,
  navigate,
  activity: { theme, products },
}: FormFieldProps) =>
  (values.maleFemaleStyles as string) ? (
    <>
      <Box marginBottom="s" key={field}>
        <LegendWrapper
          hintText="Please review size charts, sizing is generous"
          legendText={
            journeyHelper.isSportsFormExternalJourney(journey)
              ? "What size top would you like?"
              : "What size top would they like?"
          }
          required
        >
          {products
            .filter(
              (product) =>
                product.type === values.tshirtOrVest &&
                product.metaData.find(
                  // md = meta data
                  (md) => md.key === "gender"
                )?.value === values.maleFemaleStyles
            )
            .sort((a, b) => a.precedence - b.precedence)
            .map((product) => (
              <Radio
                key={product.productCode}
                // aria-label="S is small , M is medium, L is large and XL is extra large"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getFieldProps("tshirtVestSize")}
                disabled={product.enabled === "N"}
                value={product.productCode}
                checked={values.tshirtVestSize === product.productCode}
              >
                {product.metaData.find((md) => md.key === "size-label")
                  ?.value || navigate("/error")}
                {product.enabled === "N" && " (out of stock)"}
              </Radio>
            ))}
        </LegendWrapper>
        <ErrorText>
          {touched.tshirtVestSize ? errors.tshirtVestSize : undefined}
        </ErrorText>
      </Box>
      <Box marginBottom="s">
        <Collapse
          headerTitleText="Size guide"
          aria-label="Click the chevron to get the size guide"
          id="t-shirt-vest-size-guide"
        >
          <SizeGuide values={values} journey={journey} theme={theme} />
        </Collapse>
      </Box>
    </>
  ) : null;

export default RadioSelectTShirtVestSize;
