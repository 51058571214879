/* eslint-disable eslint-comments/disable-enable-pair */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);
import { ReactQueryDevtools } from "react-query/devtools";
const { REACT_APP_DATA_DOG_ENV = "development" } = process.env;

function render() {
  root.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {REACT_APP_DATA_DOG_ENV === "development" ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : (
        <></>
      )}
    </QueryClientProvider>
  );
}

function main() {
  if (process.env.NODE_ENV === "production" && !window.Cypress) {
    // if the user somehow arrived at a subpage of the form like /urlName/your-address,
    // redirect them to the first page /urlName but use negative lookahead to
    // exclude /error, /admin and /urlName/thank-you pages from this check

    const match = window.location.pathname.match(
      /^\/(?!error|admin|.*\/thank-you)(?<urlName>.*)\/.*$/
    );

    if (match) {
      window.location.pathname = `/${match?.groups?.urlName}`;

      return;
    }
  }

  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const axe = require("react-axe");
    axe(React, ReactDOM, 2000);
  }

  if (process.env.REACT_APP_AMWS_ENABLED === "no") {
    // eslint-disable-next-line promise/always-return, promise/catch-or-return
    import("./utils/createMirageServer").then((createMirageServer) => {
      createMirageServer.default();
      render();
    });
  } else {
    render();
  }
}

main();
